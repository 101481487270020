<template>
  <confirm-dialog
      :title="$t('updateCalendarDialog.title')"
      :value="options.display"
      :persistent="true"
      :loading="loading"
      @cancel="onCancel"
      @valid="onSaveMilestones"
  >
    <template v-slot:body>
      <v-form>
        <update-calendar-date-picker
            :milestone.sync="options.milestoneUpdated"
        />
        <update-calendar-time-picker
            :milestone.sync="options.milestoneUpdated"
        />
      </v-form>
    </template>
  </confirm-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UpdateCalendarDialog',

  components: {
    ConfirmDialog: () => import('@/components/Template/ConfirmDialog'),
    UpdateCalendarDatePicker: () =>
        import('@/components/GeneralCalendar/UpdateCalendarDatePicker'),
    UpdateCalendarTimePicker: () =>
        import('@/components/GeneralCalendar/UpdateCalendarTimePicker'),
  },

  props: {
    options: {
      required: true,
      type: Object,
    },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    ...mapActions([
      'displaySnackbar',
      'loadUserCalendar',
      'updateMilestone',
    ]),
    onSaveMilestones () {
      this.loading = true

      this.updateMilestone(this.options.milestoneUpdated)
          .then(() => {
            this.loadUserCalendar(this.options.milestoneUpdated.userId)
                .then(() => {
                      this.loading = false
                      this.$emit('update:options', {
                        milestoneUpdated: null,
                        display: false,
                      })
                      this.displaySnackbar({
                        status: true,
                        type: 'info',
                        text: this.$t('updateCalendarDialog.updated'),
                      })
                    },
                )
          })
          .catch(error => {
            this.displaySnackbar({
              status: true,
              type: 'error',
              text: error.response.data.date.length !== undefined
                    ? error.response.data.date[0]
                    : this.$t('errors.networkError'),
            })
            this.loading = false
          })
    },
    onCancel () {
      this.$emit('update:options', {
        milestoneUpdated: null,
        display: false,
      })
    },
  },
}
</script>
